import React from 'react'
import LazyLoad from 'react-lazyload'

const AboutCompany = () => {
  return (
    <>
      <div className='about-details-page pt-140 pb-110'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='about-details-info'>
                <div className='tp-section-wrapper'>
                  <span className='tp-section-subtitle mb-25'>
                    About our Company
                  </span>
                  <h2 className='tp-pt-size mb-40'>
                    Empowering Solutions Unveil Our Company's Commitment to
                    Excellence
                  </h2>
                </div>
                <div className='about-tb-content'>
                  <nav>
                    <div className='nav mb-30' id='nav-tab' role='tablist'>
                      <button
                        className='nav-links active'
                        id='mission'
                        data-bs-toggle='tab'
                        data-bs-target='#nav-mission'
                        type='button'
                        role='tab'
                        aria-controls='nav-mission'
                        aria-selected='true'
                      >
                        Our Mission
                      </button>

                      <button
                        className='nav-links'
                        id='nav-vission-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#nav-vission'
                        type='button'
                        role='tab'
                        aria-controls='nav-vission'
                        aria-selected='false'
                      >
                        Our Vision
                      </button>
                      <button
                        className='nav-links'
                        id='nav-value-tab'
                        data-bs-toggle='tab'
                        data-bs-target='#nav-value'
                        type='button'
                        role='tab'
                        aria-controls='nav-value'
                        aria-selected='false'
                      >
                        Our Values
                      </button>
                    </div>
                  </nav>
                  <div className='tab-content' id='nav-tabContent'>
                    <div
                      className='tab-pane fade show active'
                      id='nav-mission'
                      role='tabpanel'
                      aria-labelledby='nav-mission'
                    >
                      <p>
                        Nurtured within our company is a steadfast commitment to
                        excellence. Our mission is not just to provide services;
                        it's to transform aspirations into achievements. We
                        believe in securing success for every individual and
                        company we partner with, striving to elevate their
                        potential and realize their goals. Through our
                        comprehensive suite of services, from meticulous
                        bookkeeping to strategic financial analysis, we empower
                        our clients to navigate challenges and seize
                        opportunities confidently. Our mission isn't merely
                        about transactions; it's about building enduring
                        relationships grounded in trust, integrity, and mutual
                        success.
                      </p>

                      <p>
                        Driven by our mission, we embark on a journey of
                        continuous improvement and innovation. We are dedicated
                        to delivering unparalleled value to our clients,
                        ensuring that every interaction is infused with
                        expertise, efficiency, and personalized attention. Our
                        commitment to excellence permeates every aspect of our
                        operations, from the precision of our payroll processing
                        to the strategic insights we offer for sustainable
                        growth. At the core of our mission is a deep-seated
                        belief in the transformative power of collaboration and
                        shared success. Together, we forge pathways to
                        prosperity, unlocking new horizons of opportunity and
                        prosperity for all those we serve.
                      </p>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='nav-vission'
                      role='tabpanel'
                      aria-labelledby='nav-vission-tab'
                    >
                      <p>
                        Deeply ingrained within our company are a set of values
                        that underpin every aspect of our operations. Our vision
                        extends beyond the confines of conventional success;
                        it's about catalyzing transformative change and
                        fostering a future where innovation thrives. We envision
                        a world where every individual and organization has the
                        tools, resources, and support they need to realize their
                        fullest potential. Our commitment to this vision drives
                        us to continuously push boundaries, challenge the status
                        quo, and pioneer new frontiers of excellence.
                      </p>

                      <p>
                        Embedded within our vision is a deep sense of purpose
                        and responsibility. We aspire to be more than just a
                        service provider; we aim to be a catalyst for positive
                        change in the lives of our clients and the communities
                        we serve. Our vision propels us to leverage our
                        expertise, insights, and networks to create sustainable
                        value and lasting impact. Whether it's revolutionizing
                        financial management or empowering businesses to thrive
                        in the digital age, our vision guides us in crafting
                        innovative solutions that address the evolving needs of
                        our stakeholders. Together, we embark on a journey
                        towards a brighter tomorrow, fueled by our unwavering
                        commitment to realizing our collective vision of success
                        and prosperity.
                      </p>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='nav-value'
                      role='tabpanel'
                      aria-labelledby='nav-value-tab'
                    >
                      <p>
                        At the heart of our company are a set of values that
                        form the foundation of all our endeavors. Reliability is
                        not just a word for us; it's a commitment woven into the
                        fabric of our operations. We understand the importance
                        of trust and dependability, which is why we strive to
                        deliver consistent, high-quality services that our
                        clients can rely on. Our unwavering dedication to
                        security underscores our commitment to safeguarding the
                        interests and assets of our clients. We prioritize data
                        integrity, confidentiality, and compliance to ensure
                        that our clients feel secure and protected every step of
                        the way.
                      </p>

                      <p>
                        Commitment is more than a virtue; it's a way of life at
                        our company. We are deeply committed to the success and
                        well-being of our clients, employees, and stakeholders.
                        This commitment manifests in our relentless pursuit of
                        excellence, our willingness to go above and beyond, and
                        our proactive approach to addressing challenges. As a
                        company founded on the principles of integrity and
                        ethics, we hold ourselves to the highest standards of
                        honesty, transparency, and accountability. We believe in
                        the power of collaboration and community, and we are
                        dedicated to making a positive difference in the lives
                        of those we serve. With these values as our guiding
                        principles, we forge ahead with confidence, knowing that
                        we are building a better future for our clients and our
                        company alike.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-6 text-end'>
              <div className='tp-about-img-box p-relative d-inline-block mb-30'>
                <div className='about-page-img'>
                  <LazyLoad>
                    <img src='assets/img/about/about-page.svg' alt='' />
                  </LazyLoad>
                </div>
                <div className='dots-img'>
                  <LazyLoad>
                    <img src='assets/img/about/dot.jpg' alt='' />
                  </LazyLoad>
                </div>
                <div className='about-info-box d-flex flex-column justify-content-center text-center'>
                  <h3 className='box-title-about'>PASSIONATE</h3>
                  <h4 className='box-subtitle'>Pursuit of Excellence</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutCompany
