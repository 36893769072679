import { useEffect } from 'react'
import useScrollToTop from '../../hooks/use-scroll-to-top'

const BackToTop = () => {
  const { stick, onClickHandler } = useScrollToTop()

  useEffect(() => {
    const progressPath = document.querySelector('.progress-wrap path')
    const pathLength = progressPath.getTotalLength()
    progressPath.style.transition = progressPath.style.WebkitTransition = 'none'
    progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`
    progressPath.style.strokeDashoffset = pathLength
    progressPath.getBoundingClientRect()
    progressPath.style.transition = progressPath.style.WebkitTransition =
      'stroke-dashoffset 10ms linear'
    const updateProgress = () => {
      const scroll = window.scrollY
      const docHeight = document.body.offsetHeight
      const winHeight = window.innerHeight
      const height = docHeight - winHeight
      const progress = pathLength - (scroll * pathLength) / height
      progressPath.style.strokeDashoffset = progress
    }
    updateProgress()
    window.addEventListener('scroll', updateProgress)
  })

  return (
    <div
      className={`progress-wrap ${stick && 'active-progress'}`}
      role='button'
      onClick={onClickHandler}
      onKeyUp={e => e}
      tabIndex={-1}
    >
      <svg
        className='progress-circle svg-content'
        width='100%'
        height='100%'
        viewBox='-1 -1 102 102'
      >
        <path d='M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98' />
      </svg>
    </div>
  )
}

export default BackToTop
