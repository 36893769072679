const ServiceAbout = () => {
  return (
    <>
      <div className='tp-about-section black-bg p-relative'>
        <div
          className='tp-ab-bg d-none d-lg-block'
          style={{
            backgroundImage: 'url("assets/img/service/service-bg.webp")'
          }}
        ></div>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-6 col-md-12 offset-lg-6'>
              <div className='tp-ab-text pt-100 pl-150 p-relative'>
                <div className='tp-section-wrapper'>
                  <span className='tp-section-subtitle mb-30'>
                    Unlock Your Business Potential with Our Expert Services
                  </span>
                  <h2 className='tp-section-title text-white'>
                    Comprehensive Solutions Tailored for Your Success
                  </h2>
                  <p className='mb-30'>
                    At our company, we do more than deliver services; we craft
                    comprehensive, tailored solutions that meet the unique
                    demands of your business. Our full range of services spans
                    strategic planning, implementation, and ongoing support,
                    ensuring your operations excel in a competitive landscape.
                  </p>
                  <p className='mb-20'>
                    We recognize the uniqueness of each client's needs, which is
                    why we tailor our approach to fit you perfectly. From
                    boosting efficiency to fostering growth, we support your
                    journey at every step. Our extensive services include
                    business consulting, IT solutions, and support for
                    cutting-edge technologies. Choosing us means partnering with
                    a team committed to your enduring success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceAbout
