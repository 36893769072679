import React from 'react'
import servicesData from '../../../data/ServicesDetails.json'
import LazyLoad from 'react-lazyload'

const ServiceDetailsAbout = ({ serviceId }) => {
  const serviceID = parseInt(serviceId)
  const service = servicesData.servicesData.find(
    service => service.id === serviceID
  )

  if (!service) {
    return <div>Service not found</div>
  }

  return (
    <>
      <div className='tp-service-page pt-140 pb-140'>
        <div className='container'>
          <div className='row pb-30'>
            <div className='col-lg-6 mb-30'>
              <div className='tp-sv-inner-img w-img'>
                <LazyLoad>
                  <img
                    src='/assets/img/service/service-details-1.webp'
                    alt=''
                  />
                </LazyLoad>
              </div>
            </div>
            <div className='col-lg-6 mb-30'>
              <div className='tp-sv-inner-img w-img'>
                <LazyLoad>
                  <img
                    src='/assets/img/service/service-details-2.webp'
                    alt=''
                  />
                </LazyLoad>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='tp-sv__content'>
                <h3 className='tp-sv__title'>{service?.title}</h3>
                <p>{service?.desc1}</p>
                <p>{service?.desc2}</p>
                <div className='tp-border-line d-block'>
                  <hr />
                </div>
                {/* <h4 className='tp-sv__subtitle'>
                  Consulting Roadmap: From Insight to Success
                </h4>
                <p>
                  1. Initial Consultation: • Understand the client's needs and
                  objectives. • Identify pain points and areas for improvement.
                  • Gather relevant information about the client's industry,
                  market, and competitors. 2. Analysis and Assessment: • Conduct
                  a thorough analysis of the client's current situation. •
                  Evaluate strengths, weaknesses, opportunities, and threats
                  (SWOT analysis). • Assess market trends, regulatory
                  environment, and other external factors. • Use data analytics
                  and other tools to gain insights. 3. Strategy Development: •
                  Develop a tailored strategy based on the analysis and
                  assessment. • Define clear goals and objectives. • Identify
                  key performance indicators (KPIs) to measure success. •
                  Outline specific action plans and timelines. 4.
                  Implementation: • Execute the strategy in collaboration with
                  the client. • Provide guidance and support throughout the
                  implementation process. • Monitor progress and make
                  adjustments as needed. • Ensure effective communication and
                  coordination among stakeholders. 5. Training and Capacity
                  Building: • Provide training sessions for client teams to
                  build necessary skills and knowledge. • Offer workshops,
                  seminars, or online courses tailored to the client's needs. •
                  Empower employees to take ownership of new processes and
                  technologies. 6. Continuous Improvement: • Establish a culture
                  of continuous improvement within the client's organization. •
                  Encourage feedback from employees and stakeholders. •
                  Regularly review performance metrics and adjust strategies
                  accordingly. • Stay updated on industry trends and best
                  practices. 7. Evaluation and Reporting: • Conduct regular
                  evaluations to assess the impact of the consulting services. •
                  Prepare comprehensive reports detailing progress,
                  achievements, and areas for further improvement. • Present
                  findings to key stakeholders and provide recommendations for
                  future actions. 8. Relationship Management: • Maintain open
                  and transparent communication with the client. • Foster
                  long-term relationships based on trust and mutual respect. •
                  Offer ongoing support and assistance as needed. • Seek
                  opportunities for additional collaboration or referrals.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceDetailsAbout
