import React, { useState, useEffect } from 'react'
import team_data from '../../../../src/data/teamData.json'
import LazyLoad from 'react-lazyload'

const TeamDetailsHero = ({ memberId }) => {
  const numericMemberId = parseInt(memberId, 10)
  const teamMember = team_data.find(member => member.id === numericMemberId)

  const [imageStyle, setImageStyle] = useState({
    width: 'auto',
    height: 'auto'
  })

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 768) {
        setImageStyle({
          width: 'auto',
          height: '450px'
        })
      } else {
        setImageStyle({
          width: 'auto',
          height: 'auto'
        })
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (!teamMember) {
    return <p>Team member not found!</p>
  }

  const {
    img,
    name,
    title,
    expertise,
    experience,
    email,
    phone,
    social_links
  } = teamMember

  return (
    <div className='team-single-page pb-100 pt-20'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='p-relative'>
              <LazyLoad>
                <img src={img} style={imageStyle} alt={name} />
              </LazyLoad>
              <div className='team-info-box white-bg pr-100 pl-100 pt-40'>
                <h3 className='t-box-title'>{name}</h3>
                <span>{title}</span>
                <div className='team-member-info-list'>
                  <ul>
                    <li>Expertise: {expertise}</li>
                    <li>Experience: {experience}</li>
                    <li>E-mail: {email}</li>
                    <li>Phone: {phone}</li>
                  </ul>
                </div>
                <div className='team-social-icons mt-30'>
                  {social_links.map((link, index) => (
                    <span key={index}>
                      <a
                        href={link.link}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <i className={link.icon}></i>
                      </a>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamDetailsHero
