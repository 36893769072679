import React from 'react'
import faq_data from '../../../data/faq.json'
import SingleFaq from './SingleFaq'

const Accordion = () => {
  return (
    <>
      <div className='faq-section pt-140 pb-140'>
        <div className='container'>
          <h2 className='tp-accordion-title mb-40 text-black'>
            <span>
              Unlocking Answers: Your Guide to Atlex Consulting's FAQs
            </span>
          </h2>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
              <div className='accordion tp-accordion' id='accordionExample'>
                {faq_data.faq
                  .slice(0, Math.ceil(faq_data.faq.length / 2))
                  .map(item => (
                    <SingleFaq
                      key={item.id}
                      item={item}
                      parent='accordionExample'
                    />
                  ))}
              </div>
            </div>
            <div className='col-lg-6 col-md-6'>
              <div className='accordion tp-accordion' id='accordionExample2'>
                {faq_data.faq
                  .slice(Math.ceil(faq_data.faq.length / 2))
                  .map(item => (
                    <SingleFaq
                      key={item.id}
                      item={item}
                      parent='accordionExample2'
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Accordion
