import React from 'react'
import { useParams } from 'react-router-dom'
import FooterThree from '../../common/Footer/FooterThree'
import Menu from '../../common/Menu/Menu'
import ContactWithTeam from './ContactWithTeam'
import SingleTeamBanner from './SingleTeamBanner'
import TeamDetailsAbout from './TeamDetailsAbout'
import TeamDetailsHero from './TeamDetailsHero'
import { Fade } from 'react-awesome-reveal'

const TeamDetails = () => {
  const { memberId } = useParams()

  return (
    <>
      <Menu />
      <Fade cascade damping={0.1} triggerOnce={true} duration={600}>
        <SingleTeamBanner />
        <TeamDetailsHero memberId={memberId} />
        <TeamDetailsAbout memberId={memberId} />
        <ContactWithTeam />
        <FooterThree />
      </Fade>
    </>
  )
}

export default TeamDetails
