import React from 'react'
import { Link } from 'react-router-dom'
import './assets/css/bootstrap.css'
import './assets/css/error-page.css'
import './assets/css/error-page-responsive.css'
import LazyLoad from 'react-lazyload'

const Error404 = () => {
  return (
    <>
      <LazyLoad>
        <section className='page-section'>
          <div className='full-width-screen'>
            <div className='container-fluid'>
              <div className='content-detail'>
                <div className='pendulum-platform'>
                  <div className='pendulum-holder'></div>
                  <div className='pendulum-thread'>
                    <div className='pendulum-knob'></div>
                    <div className='pendulum'>404</div>
                  </div>
                </div>
                <div className='text-detail'>
                  <h4 className='sub-title'>Oops!</h4>
                  <p className='detail-text'>
                    The page you were looking for doesn't exist
                  </p>{' '}
                  <p className='detail-subtext'>
                    You may have mistyped the address or the page may have moved
                  </p>{' '}
                  <div className='back-btn'>
                    <Link to='/' className='tp-btn-404'>
                      Back to Home
                      <span>
                        <svg
                          width='22'
                          height='8'
                          viewBox='0 0 22 8'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z'
                            fill='currentColor'
                          ></path>
                        </svg>
                        <svg
                          width='22'
                          height='8'
                          viewBox='0 0 22 8'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z'
                            fill='currentColor'
                          ></path>
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <script src='./assets/js/jquery-3.5.1.min.js'></script>
        <script src='./assets/js/script.js'></script>
      </LazyLoad>
    </>
  )
}

export default Error404
