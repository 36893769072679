import React from 'react'
import FooterThree from '../../common/Footer/FooterThree'
import Menu from '../../common/Menu/Menu'
import TeamAbout from './TeamAbout'
import TeamBanner from './TeamBanner'
import TopCtaAreaTeam from '../../Home/TopCtaAreaTeam'
import TeamContact from './TeamContact'
import { Fade } from 'react-awesome-reveal'

const TeamPage = () => {
  return (
    <>
      <Menu />
      <Fade cascade damping={0.1} triggerOnce={true} duration={600}>
        <TeamBanner />
        <TopCtaAreaTeam />
        <TeamAbout />
        <TeamContact />
        <FooterThree />
      </Fade>
    </>
  )
}

export default TeamPage
