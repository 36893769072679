import React from 'react'
import FooterThree from '../common/Footer/FooterThree'
import Menu from '../common/Menu/Menu'
import Feature from './Feature'
import Services from './Services'
import Testimonial from '../common/Testimonial/Testimonial'
import Brands from '../common/Brands/Brands'
import TopAbout from './TopAbout'
import TopCtaArea from './TopCtaArea'
import TopFooter from '../common/TopFooter/TopFooter'
import HomeSlider from './HomeSlider'
import ContactThree from './ContactThree'
import { Fade } from 'react-awesome-reveal'

const Home = () => {
  return (
    <>
      <Menu />
      <Fade cascade damping={0.1} triggerOnce={true} duration={600}>
        <HomeSlider />
        <TopAbout />
        <TopCtaArea />
        <Services />
        <Feature />
        <Testimonial />
        <Brands />
        <ContactThree />
        <TopFooter />
        <FooterThree />
      </Fade>
    </>
  )
}

export default Home
