import React from 'react'
import LazyLoad from 'react-lazyload'

const TopCtaArea = () => {
  return (
    <>
      {/* <!-- cta area start --> */}
      <div className='tp-cta-area'>
        <div className='container'>
          <div className='tp-cta-wrapper pt-20 pb-60'>
            <div className='row align-items-center justify-content-evenly'>
              <div className='col-lg-6'>
                <div className='tp-section-wrapper'>
                  <span className='tp-section-subtitle mb-25'>
                    Unlock Your Business Potential
                  </span>
                  <h2 className='tp-section-title'>
                    Empowering Success: Where Visionaries Meet Victory with Our
                    Team
                  </h2>
                  <p className='.tp-section-title-sm'>
                    Embark on a transformative journey with our dedicated team,
                    where ideas blossom into triumphs. Rooted in compassion and
                    driven by relentless effort, our team endeavors to turn your
                    visions into reality. With services spanning from meticulous
                    bookkeeping to comprehensive IT support, we are committed to
                    providing holistic solutions tailored to your needs. At our
                    core lies a passionate dedication to your success, fueling
                    every step of your journey with expertise and unwavering
                    support. Trust in us as we guide you towards the pinnacle of
                    achievement, together.
                  </p>
                </div>
              </div>
              <div className='col-lg-6 d-flex justify-content-end'>
                <LazyLoad>
                  <img src='assets/img/team/team-page-post.svg' alt='' />
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- cta end end --> */}
    </>
  )
}

export default TopCtaArea
