import React from 'react'
import { useParams } from 'react-router-dom'
import Contact from '../../common/Contact/Contact'
import FooterThree from '../../common/Footer/FooterThree'
import Menu from '../../common/Menu/Menu'
import ServiceDetailsAbout from './ServiceDetailsAbout'
import ServiceDetailsBanner from './ServiceDetailsBanner'
import { Fade } from 'react-awesome-reveal'

const ServiceDetails = () => {
  const { serviceId } = useParams()
  return (
    <>
      <Menu />
      <Fade cascade damping={0.1} triggerOnce={true} duration={600}>
        <ServiceDetailsBanner />
        <ServiceDetailsAbout serviceId={serviceId} />
        <Contact />
        <FooterThree />
      </Fade>
    </>
  )
}

export default ServiceDetails
