const menu_data = [
  {
    id: 1,
    title: 'Home',
    link: '/'
  },
  {
    id: 2,
    title: 'About Us',
    link: '/about'
  },
  {
    id: 3,
    title: 'Services',
    link: '/services'
  },
  {
    id: 4,
    title: 'Team',
    link: '/team'
  },
  {
    id: 5,
    title: 'Contact',
    link: '/contact'
  }
]

export default menu_data
