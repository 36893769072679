import React from 'react'
import Brands from '../../common/Brands/Brands'
import Contact from '../../common/Contact/Contact'
import FooterThree from '../../common/Footer/FooterThree'
import Menu from '../../common/Menu/Menu'
import Testimonial from '../../common/Testimonial/Testimonial'
import OurService from './OurService'
import ServiceAbout from './ServiceAbout'
import ServiceBanner from './ServiceBanner'
import { Fade } from 'react-awesome-reveal'

const Service = () => {
  return (
    <>
      <Menu />
      <Fade cascade damping={0.1} triggerOnce={true} duration={600}>
        <ServiceBanner />
        <OurService />
        <ServiceAbout />
        <Contact />
        <Testimonial />
        <Brands />
        <FooterThree />
      </Fade>
    </>
  )
}

export default Service
