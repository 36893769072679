import React from 'react'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'

const Feature = () => {
  return (
    <>
      {/* <!-- feature area start  --> */}
      <div className='tp-feature-area position-relative'>
        <LazyLoad>
          <div
            className='tp-fe-bg d-none d-lg-block'
            style={{
              backgroundImage: 'url(/assets/img/bg/feature-bg.webp)'
            }}
          ></div>
        </LazyLoad>
        <div className='container-fluid p-0'>
          <div className='row g-0 justify-content-end'>
            <div className='col-xl-4 col-lg-12 tp-modify-width d-block'>
              <div className='tp-section-title-sm-box tp-white-text grey-bg-3 hide-content'>
                <span className='tp-section-subtitle-sm'>
                  Lift Your Business to New Heights
                </span>
                <h3 className='tp-section-title-sm tp-white-text-sm mb-5'>
                  Strategic Team Crafting Business Solutions
                </h3>
                <p>
                  Empowering businesses with tailored solutions, our strategic
                  team excels in bookkeeping, tax preparation, payroll
                  processing, financial analysis, accounts payable, human
                  resources, and IT services.
                </p>
                <Link className='tp-btn-sm' to='/about'>
                  <span>
                    <svg
                      width='36'
                      height='8'
                      viewBox='0 0 36 8'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z'
                        fill='currentColor'
                      />
                    </svg>
                    <svg
                      width='36'
                      height='8'
                      viewBox='0 0 36 8'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                  View MOre
                </Link>
              </div>
              <div className='tp-section-title-sm-box tp-white-text black-bg hide-content'>
                <span className='tp-section-subtitle-sm'>
                  Magnifying business success
                </span>
                <h3 className='tp-section-title-sm tp-white-text-sm mb-5'>
                  Driving Success Through Strategic Planning and Execution
                </h3>
                <p>
                  Dedicated to achieving goals, we meticulously plan and execute
                  strategies, guided by discipline and unwavering commitment,
                  ensuring success at every turn.
                </p>
                <Link className='tp-btn-sm' to='/about'>
                  <span>
                    <svg
                      width='36'
                      height='8'
                      viewBox='0 0 36 8'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z'
                        fill='currentColor'
                      />
                    </svg>
                    <svg
                      width='36'
                      height='8'
                      viewBox='0 0 36 8'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z'
                        fill='currentColor'
                      />
                    </svg>
                  </span>
                  View MOre
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Feature
