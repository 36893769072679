import React from 'react'

const TopFooter = () => {
  return (
    <div className='tp-footer__area black-bg'>
      <div className='tp-footer'>
        {/* footer top */}
        <div className='tp-footer__top pt-90 pb-60'>
          <div className='container'>
            <div className='row align-items-lg-center'>
              <div className='col-lg-5'>
                <h3 className='tp-footer__top-title'>
                  Have a project in mind? Let’s work together.
                </h3>
              </div>
              <div className='col-lg-7'>
                <div className='map-container'>
                  <iframe
                    title='map'
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23581.1926165759!2d21.14401268270156!3d42.3713174493734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13547e5d2cdacd71%3A0xec0e1a604157e8b5!2sFerizaj!5e0!3m2!1sen!2s!4v1715254669625!5m2!1sen!2s'
                    style={{ border: '0', width: '100%', height: '300px' }}
                    allowFullScreen=''
                    loading='lazy'
                    referrerPolicy='no-referrer-when-downgrade'
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* footer top end */}
      </div>
    </div>
  )
}

export default TopFooter
