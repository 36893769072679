import React from 'react'
import Contact from '../../common/Contact/Contact'
import FooterThree from '../../common/Footer/FooterThree'
import Menu from '../../common/Menu/Menu'
import Accordion from './Accordion'
import FaqBanner from './FaqBanner'
import { Fade } from 'react-awesome-reveal'

const FAQ = () => {
  return (
    <>
      <Menu />
      <Fade cascade damping={0.1} triggerOnce={true} duration={600}>
        <FaqBanner />
        <Accordion />
        <Contact />
        <FooterThree />
      </Fade>
    </>
  )
}

export default FAQ
