import React from 'react'
import { Link } from 'react-router-dom'
import MobileMenus from './MobileMenus'
import LazyLoad from 'react-lazyload'

const OffCanvas = ({ setOffCanvas, isOffCanvas }) => {
  return (
    <>
      <div
        className={`offcanvas__area off-canvas-bg ${
          isOffCanvas ? 'opened' : ''
        }`}
        style={{
          backgroundImage: 'url("/assets/img/bg/sidebar-bg.svg")'
        }}
      >
        <div className='offcanvas_area-logo'>
          <span className='offcanvas__close-btn'>
            <button onClick={() => setOffCanvas(false)}>
              <i className='fal fa-times' />
            </button>
          </span>
        </div>
        <div className='offcanvas-content'>
          <div className='offcanvas-subtitle'>
            <span>Ascend Your Business with</span>
          </div>
          <LazyLoad>
            <div className='offcanvas-logo pb-20 pt-0'>
              <img
                src='/assets/img/logo/atlex-dark.webp'
                style={{ width: 'auto', height: '80px' }}
                alt='logo'
              />
            </div>
          </LazyLoad>
          <div className='offcanva-details'>
            <p>
              Supporting businesses with expert guidance and inventive solutions
            </p>
          </div>
          <div className='offcanva-btn'>
            <Link
              to='/contact'
              className='tp-slider-btn d-inline-block mt-40'
              tabIndex='-1'
            >
              <span>
                <svg
                  width='53'
                  height='8'
                  viewBox='0 0 53 8'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z'
                    fill='currentColor'
                  ></path>
                </svg>
                <svg
                  width='53'
                  height='8'
                  viewBox='0 0 53 8'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M52.3536 4.35356C52.5488 4.15829 52.5488 3.84171 52.3536 3.64645L49.1716 0.464469C48.9763 0.269207 48.6597 0.269207 48.4645 0.464469C48.2692 0.659731 48.2692 0.976314 48.4645 1.17158L51.2929 4L48.4645 6.82843C48.2692 7.02369 48.2692 7.34027 48.4645 7.53554C48.6597 7.7308 48.9763 7.7308 49.1716 7.53554L52.3536 4.35356ZM-3.11023e-08 4.5L52 4.5L52 3.5L3.11023e-08 3.5L-3.11023e-08 4.5Z'
                    fill='currentColor'
                  ></path>
                </svg>
              </span>
              Get in touch
            </Link>
          </div>
          <Link to='/contact' className='consult-btn mt-15'>
            Free Consultation
            <span>
              <svg
                width='22'
                height='8'
                viewBox='0 0 22 8'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z'
                  fill='currentColor'
                ></path>
              </svg>
              <svg
                width='22'
                height='8'
                viewBox='0 0 22 8'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z'
                  fill='currentColor'
                ></path>
              </svg>
            </span>
          </Link>
        </div>

        <div className='tp-mobile-menu mean-container d-block d-xl-none'>
          <div className='mean-bar'>
            <MobileMenus />
          </div>
        </div>

        <div className='tp-footer__widget mb-50 offcanvas-ct-info'>
          <h3 className='tp-footer__widget-title'>Quick Links</h3>
          <ul>
            <li>
              <span>Ferizaj, Kosovo 70000</span>
            </li>
            <li>
              <Link to='tel:+38345631664'>(+383)45 631 664</Link>
            </li>
            <li>
              <Link to='mailto:atlexconsulting05@gmail.com'>
                atlexconsulting05@gmail.com
              </Link>
            </li>
            <li>
              <span> Office Hours: 7AM - 7PM</span>
            </li>
            <li>
              <span> Monday - Friday</span>
            </li>
          </ul>
        </div>
      </div>
      <div
        onClick={() => setOffCanvas(false)}
        className={`body-overlay ${isOffCanvas ? 'opened' : ''}`}
      ></div>
    </>
  )
}

export default OffCanvas
