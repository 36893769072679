import React from 'react'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'

const FooterThree = () => {
  return (
    <footer>
      <div className='tp-footer__area black-bg'>
        <div className='tp-footer'>
          {/* <!-- main-footer start  --> */}
          <div className='tp-footer__main'>
            <div className='container'>
              <div className='tp-footer-border pt-20 pb-10'>
                <div className='pb-30'>
                  <div className='row'>
                    <div className='col-12 col-md-6 pt-110 order-md-2 text-end'>
                      <div className='footer-form-2 mb-30'>
                        <form>
                          <input type='email' placeholder='Enter your mail' />
                          <button type='submit'>
                            Subscribe <i className='fal fa-paper-plane'></i>
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className='col-12 col-md-6 order-md-1'>
                      <div className='footer-logo mb-0'>
                        <LazyLoad>
                          <Link to='/'>
                            <img
                              src='/assets/img/logo/atlex-dark.webp'
                              className='footer-logo-img'
                              alt='logo'
                            />
                          </Link>
                        </LazyLoad>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-3 col-md-6 pr-60'>
                    <p>
                      Atlex Consulting, your trusted partner for comprehensive
                      financial management, HR solutions, payroll services, IT
                      support, and more. With a focus on efficiency and
                      innovation, we empower businesses to achieve sustainable
                      growth and future success.
                    </p>
                  </div>
                  <div className='col-lg-3 col-md-6'>
                    <div className='tp-footer__widget tp-footer__2 pb-30'>
                      <h3 className='tp-footer__widget-title text-white'>
                        Our Service
                      </h3>
                      <ul>
                        <li>
                          <Link to='/service-details/1'>Bookkeeping</Link>
                        </li>
                        <li>
                          <Link to='/service-details/2'>Tax Preparation</Link>
                        </li>
                        <li>
                          <Link to='/service-details/3'>
                            Payroll Processing
                          </Link>
                        </li>
                        <li>
                          <Link to='/service-details/4'>
                            Financial Analysis
                          </Link>
                        </li>
                        <li>
                          <Link to='/service-details/5'>Accounts Payable</Link>
                        </li>
                        <li>
                          <Link to='/service-details/6'>Human Resource</Link>
                        </li>
                        <li>
                          <Link to='/service-details/7'>IT Services</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6 '>
                    <div className='tp-footer__widget tp-footer__2  pb-30'>
                      <h3 className='tp-footer__widget-title text-white'>
                        Quick Links
                      </h3>
                      <ul>
                        <li>
                          <Link to='/team'>Our Team</Link>
                        </li>
                        <li>
                          <Link to='/faq'>FAQ</Link>
                        </li>
                        <li>
                          <Link to='/services'>Services</Link>
                        </li>
                        <li>
                          <Link to='/contact'>Contact</Link>
                        </li>
                        <li>
                          <Link to='/contact'>Career</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-lg-3 col-md-6'>
                    <div className='tp-footer__widget tp-footer__2 pb-30'>
                      <h3 className='tp-footer__widget-title text-white'>
                        Reach to us
                      </h3>
                      <ul>
                        <li>
                          <Link to='#'>Ferizaj, Kosovo 70000</Link>
                        </li>
                        <li>
                          <Link to='tel:+38345631664'>(+383)45 631 664</Link>
                        </li>
                        <li>
                          <Link to='mailto:atlexconsulting05@gmail.com'>
                            atlexconsulting05@gmail.com
                          </Link>
                        </li>
                        <li>
                          <span> Office Hours: 7AM - 7PM</span>
                        </li>
                        <li>
                          <span> Monday - Friday</span>
                        </li>
                        <div className='tp-footer-social-icon mt-20'>
                          <div>
                            <a
                              href='https://www.facebook.com/profile.php?id=100092704251637'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <i className='fab fa-facebook'></i>
                            </a>
                            <a
                              href='https://www.linkedin.com/company/atlex-consulting/'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <i className='fab fa-linkedin'></i>
                            </a>
                            <a
                              href='https://twitter.com'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <i className='fab fa-twitter'></i>
                            </a>
                            <a
                              href='https://www.instagram.com'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <i className='fab fa-instagram'></i>
                            </a>
                          </div>
                        </div>

                        {/* <div className='tp-footer-social-icon text-md-end mt-20'>
                          <ul>
                            <li>
                              <Link to='/'>
                                <i className='fab fa-facebook'></i>
                              </Link>
                            </li>
                            <li>
                              <Link to='/'>
                                <i className='fab fa-twitter'></i>
                              </Link>
                            </li>
                            <li>
                              <Link to='/'>
                                <i className='fab fa-instagram'></i>
                              </Link>
                            </li>
                            <li>
                              <Link to='/'>
                                <i className='fab fa-youtube'></i>
                              </Link>
                            </li>
                            <li>
                              <Link to='/'>
                                <i className='fab fa-linkedin'></i>
                              </Link>
                            </li>
                          </ul>
                        </div> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- main footer end  --> */}
        </div>
      </div>

      {/* <!-- footer copy right --> */}
      <div className='top-footer-copyright pt-30 pb-30 black-bg'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='tp-copyrigh-text text-center text-white'>
                <span>
                  © {new Date().getFullYear()} Atlex Consulting . All Rights
                  Reserved
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer copyright end  --> */}
    </footer>
  )
}

export default FooterThree
