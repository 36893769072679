import React from 'react'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'

const AboutBanner = () => {
  return (
    <>
      {/* <!-- breadcrumb area start --> */}
      <LazyLoad>
        <section
          className='breadcrumb__area include-bg breadcrumb__overlay about_banner'
          style={{
            backgroundImage:
              'url("/assets/img/breadcrumb/banner-gb-general.webp")'
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xxl-12'>
                <div className='breadcrumb__content p-relative z-index-1 text-center'>
                  <h3 className='breadcrumb__title'>About us</h3>
                  <div className='breadcrumb__list'>
                    <span>
                      <Link to='/'>Home</Link>
                    </span>
                    <span className='dvdr'>:</span>
                    <span>About us</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LazyLoad>
      {/* <!-- breadcrumb area end --> */}
    </>
  )
}

export default AboutBanner
