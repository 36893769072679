import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { SpeedInsights } from '@vercel/speed-insights/react'
// internal
import BackToTop from '../components/BackToTop'
import AnimateMouse from '../components/common/AnimateMouse'
import ContextProvider from '../components/context/ContextProvider'
import Home from '../components/Home/Home'
import About from '../components/Pages/About/About'
import ContactUs from '../components/Pages/ContactUs/ContactUs'
import FAQ from '../components/Pages/FAQ/FAQ'
import Services from '../components/Pages/Service/Service'
import ServiceDetails from '../components/Pages/ServiceDetails/ServiceDetails'
import TeamDetails from '../components/Pages/TeamDetails/TeamDetails'
import TeamPage from '../components/Pages/TeamPage/TeamPage'
import NotFoundPage from '../components/Pages/404/NotFoundPage'

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const AppNavigation = () => {
  const location = useLocation()

  useEffect(() => {
    let pageTitle = 'Atlex Consulting'

    if (location.pathname !== '/') {
      let pageName = ''

      if (location.pathname === '/services') {
        pageName = 'Services'
      } else if (location.pathname === '/about') {
        pageName = 'About Us'
      } else if (location.pathname === '/team') {
        pageName = 'Team'
      } else if (location.pathname.startsWith('/service-details')) {
        pageName = 'Service'
      } else if (location.pathname.startsWith('/team-details')) {
        pageName = 'Team'
      } else {
        pageName = capitalizeFirstLetter(location.pathname.slice(1))
      }

      pageTitle = `${pageName} - ${pageTitle}`
    } else {
      pageTitle = `Home - ${pageTitle}`
    }

    document.title = pageTitle
  }, [location.pathname])

  return (
    <ContextProvider>
      <div className='d-none d-lg-block'>
        {' '}
        <AnimateMouse />
      </div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/team' element={<TeamPage />} />
        <Route path='/team-details/:memberId' element={<TeamDetails />} />
        <Route path='/services' element={<Services />} />
        <Route
          path='/service-details/:serviceId'
          element={<ServiceDetails />}
        />
        <Route path='/contact' element={<ContactUs />} />
        <Route path='/faq' element={<FAQ />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      <SpeedInsights />
      <BackToTop />
    </ContextProvider>
  )
}

export default AppNavigation
