import React from 'react'
import Brands from '../../common/Brands/Brands'
import FooterThree from '../../common/Footer/FooterThree'
import Menu from '../../common/Menu/Menu'
import Testimonial from '../../common/Testimonial/Testimonial'
import Services from '../../Home/Services'
import AboutBanner from './AboutBanner'
import AboutCompany from './AboutCompany'
import AboutFeature from './AboutFeature'
import Accordion from '../FAQ/Accordion'
import { Fade } from 'react-awesome-reveal'

const About = () => {
  return (
    <>
      <Menu />
      <Fade cascade damping={0.1} triggerOnce={true} duration={600}>
        <AboutBanner />
        <AboutCompany />
        <Services />
        <AboutFeature />
        <Testimonial />
        <Brands />
        <Accordion />
        <FooterThree />
      </Fade>
    </>
  )
}

export default About
