import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import menu_data from './MobileData'

const MobileMenus = () => {
  const [navTitle, setNavTitle] = useState('')

  const openMobileMenu = menu => {
    if (navTitle === menu) {
      setNavTitle('')
    } else {
      setNavTitle(menu)
    }
  }

  return (
    <nav className='mean-nav'>
      <ul>
        {menu_data.map(menu => (
          <li key={menu.id}>
            {menu.hasDropdown ? (
              <>
                <Link to={menu.link}>{menu.title}</Link>
                <ul
                  className='submenu'
                  style={{
                    display: navTitle === menu.title ? 'block' : 'none'
                  }}
                >
                  {menu.submenus &&
                    menu.submenus.map(sub => (
                      <li key={sub.id}>
                        <Link to={sub.link}>{sub.title}</Link>
                      </li>
                    ))}
                </ul>
                <Link
                  className={`mean-expand ${
                    navTitle === menu.title ? 'mean-clicked' : ''
                  }`}
                  to='#'
                  onClick={() => openMobileMenu(menu.title)}
                  style={{ fontSize: '18px' }}
                >
                  <i className='fal fa-plus'></i>
                </Link>
              </>
            ) : (
              <Link to={menu.link}>{menu.title}</Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default MobileMenus
