import React from 'react'
import { Link } from 'react-router-dom'
import team_data from '../../../data/teamData.json'
import LazyLoad from 'react-lazyload'

const TeamAbout = () => {
  return (
    <>
      <div className='tp-team-area pt-150 pb-80'>
        <div className='container'>
          <h2 className='tp-section-title-team mb-60 text-black'>
            Meet Our Dedicated Team: Bringing Expertise, Passion, and Commitment
            to Every Project
          </h2>
          <div className='row'>
            {team_data.map((team, i) => (
              <div key={i} className='col-xl-3 col-md-6'>
                <div className='tp-team pb-30 pt-25'>
                  <div className='tp-team__thumb'>
                    <LazyLoad>
                      <img src={team.img} alt='' />
                    </LazyLoad>
                  </div>
                  <div className='tp-team__info pt-25'>
                    <h3 className='tp-team-name'>
                      <Link to={`/team-details/${team.id}`}>{team.name}</Link>
                    </h3>
                    <p>{team.title}</p>
                  </div>
                  <div className='tp-team__social'>
                    {team.social_links.map((l, i) => (
                      <a key={i} href={`${l.link}`} target={l.target}>
                        <i className={l.icon}></i>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamAbout
