import React from 'react'
import { Link } from 'react-router-dom'
import servicesData from '../../../data/ServicesDetails.json'

export function ServiceItem({ icon, title, desc, serviceId }) {
  const serviceID = parseInt(serviceId)
  const service = servicesData.servicesData.find(
    service => service.id === serviceID
  )

  return (
    <div className='col-xl-3 col-md-6'>
      <div className='tp-service white-bg pb-45 pl-25 pr-25 mb-30 pt-60 text-center'>
        <div className='tp-service__icon'>
          <i className={icon}></i>
        </div>
        <h3 className='tp-service__title pb-25 pt-40'>
          <Link to={`/service-details/${service.id}`}>{title}</Link>
        </h3>
        <p>{desc}</p>
        <Link className='tp-btn-sm-black' to={`/service-details/${service.id}`}>
          <span>
            <svg
              width='36'
              height='8'
              viewBox='0 0 36 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z'
                fill='currentColor'
              />
            </svg>
            <svg
              width='36'
              height='8'
              viewBox='0 0 36 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M35.3536 4.35355C35.5488 4.15829 35.5488 3.84171 35.3536 3.64644L32.1716 0.464463C31.9763 0.269201 31.6597 0.269201 31.4645 0.464463C31.2692 0.659726 31.2692 0.976308 31.4645 1.17157L34.2929 4L31.4645 6.82842C31.2692 7.02369 31.2692 7.34027 31.4645 7.53553C31.6597 7.73079 31.9763 7.73079 32.1716 7.53553L35.3536 4.35355ZM4.37114e-08 4.5L35 4.5L35 3.5L-4.37114e-08 3.5L4.37114e-08 4.5Z'
                fill='currentColor'
              />
            </svg>
          </span>
          View MOre
        </Link>
      </div>
    </div>
  )
}

const OurService = () => {
  return (
    <>
      <div className='tp-service-ara grey-bg-4 pt-140 pb-110'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='tp-section-wrapper mb-70 text-center'>
                <span className='tp-section-subtitle mb-30'>OUR SERVICES</span>
                <h2 className='tp-section-title'>Explore Our Services</h2>
              </div>
            </div>
          </div>
          <div className='row'>
            <ServiceItem
              serviceId='1'
              icon='pe-7s-notebook'
              title='Bookkeeping and Financial Record'
              desc='Accurate financial record management for compliance and precision.'
            />
            <ServiceItem
              serviceId='2'
              icon='pe-7s-note'
              title='Tax Preparation and Planning'
              desc='Expert guidance in formulating effective tax strategies and meticulous tax planning.'
            />
            <ServiceItem
              serviceId='3'
              icon='pe-7s-plugin'
              title='Payroll Processing and Management'
              desc='Efficient payroll management for accurate and punctual compensation.'
            />
            <ServiceItem
              serviceId='4'
              icon='pe-7s-cash'
              title='Financial Analysis and Reporting'
              desc='Profound insights through rigorous financial analysis and comprehensive reporting.'
            />
            <ServiceItem
              serviceId='5'
              icon='pe-7s-folder'
              title='Accounts Payable and Receivable Management'
              desc='Diligent oversight of accounts payable and receivable for optimized financial liquidity.'
            />
            <ServiceItem
              serviceId='6'
              icon='pe-7s-users'
              title='Human Resource Planning'
              desc='Holistic planning to enhance workforce management and organizational efficiency.'
            />
            <ServiceItem
              serviceId='7'
              icon='pe-7s-science'
              title='IT Services'
              desc='Comprehensive solutions to streamline your technological infrastructure and optimize digital operations.'
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default OurService
