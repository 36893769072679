import React from 'react'
import { Fade } from 'react-awesome-reveal'
import Error404 from './Error404'

const NotFoundPage = () => {
  return (
    <>
      <Fade cascade damping={0.1} triggerOnce={true} duration={600}>
        <Error404 />
      </Fade>
    </>
  )
}

export default NotFoundPage
