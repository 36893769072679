import React from 'react'
import team_data from '../../../../src/data/teamData.json'

const TeamDetailsAbout = ({ memberId }) => {
  const memberID = parseInt(memberId)
  const member = team_data.find(member => member.id === memberID)

  return (
    <>
      <div className='team-main-content pb-130'>
        <div className='container'>
          <div className='team-page-content pr-100'>
            <div className='row'>
              <div className='col-12'>
                <div className='team-page-text'>
                  <h3 className='team-title-text mb-40'>
                    About{' '}
                    <span className='team-title-name'>{member?.name}</span>
                  </h3>
                </div>
              </div>
            </div>
            <div>
              <div>
                <p>{member?.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamDetailsAbout
