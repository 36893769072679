import React from 'react'
import FooterThree from '../../common/Footer/FooterThree'
import Menu from '../../common/Menu/Menu'
import ContactInfo from './ContactInfo'
import ContactUsBanner from './ContactUsBanner'
import { Fade } from 'react-awesome-reveal'

const ContactUs = () => {
  return (
    <>
      <Menu />
      <Fade cascade damping={0.1} triggerOnce={true} duration={600}>
        <ContactUsBanner />
        <ContactInfo />
        <FooterThree />
      </Fade>
    </>
  )
}

export default ContactUs
